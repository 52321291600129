import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule, ModalController } from '@ionic/angular';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, IonicModule],
  selector: 'app-datetime-modal',
  templateUrl: './datetime-modal.component.html',
  styleUrls: ['./datetime-modal.component.scss'],
})
export class DatetimeModalComponent implements OnInit {
  @Input() title?: string;
  @Input() presentation?: string = 'date';
  @Input() selected?: Date | string;
  @Input() min?: Date | string;
  @Input() max?: Date | string;

  datetime?: string;

  constructor(private modalCtrl: ModalController) {}

  ngOnInit(): void {
    if (this.selected) {
      this.datetime = new Date(this.selected).toISOString() ?? undefined;
    } else {
      this.datetime = undefined;
    }

    if (this.min) {
      this.min = new Date(this.min).toISOString() ?? undefined;
    } else {
      this.min = undefined;
    }

    if (this.max) {
      this.max = new Date(this.max).toISOString() ?? undefined;
    } else {
      this.max = undefined;
    }
  }

  onDatetimeChange(event: CustomEvent) {
    this.datetime = event.detail.value;

    this.dismiss();
  }

  dismiss() {
    this.modalCtrl.dismiss({
      value: this.datetime,
    });
  }
}
