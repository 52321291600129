<ion-datetime
  [presentation]="presentation"
  [min]="min"
  [max]="max"
  [value]="datetime"
  (ionChange)="onDatetimeChange($event)"
  size="cover"
>
  <span slot="title" *ngIf="title">{{ title }}</span>
</ion-datetime>
